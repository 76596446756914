import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Marketplace from './components/Marketplace';
import ManagementPage from './components/ManagementPage';
import ProductDetails from './components/ProductDetails';
import Navbar from './components/Navbar';
import ReservedProducts from './components/ReservedProducts';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-marketplace-background">
        <Navbar />
        <div className="container mx-auto px-4 py-8">
          <Routes>
            <Route path="/" element={<Marketplace />} />
            <Route path="/product/:id" element={<ProductDetails />} />
            <Route path="/manage/:token" element={<ManagementPage />} />
            <Route path="/reserved/:token" element={<ReservedProducts />} />
            <Route path="/manage" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
