import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function ReservedProducts() {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useParams();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        console.log('Fetching reserved products...');
        const response = await fetch(`/api/reserved`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to fetch products');
        }

        const data = await response.json();
        console.log('Received products:', data.length);
        setProducts(data);
      } catch (err) {
        console.error('Error fetching products:', err);
        setError(err.message || 'Failed to load products');
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchProducts();
    } else {
      setLoading(false);
    }
  }, [token]);

  if (!token) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center">Authentication required</div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h2 className="text-2xl font-bold mb-8">Зарезервированные товары ({products.length})</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {products.map(product => (
          <div key={product.id} className="bg-white shadow-md rounded-lg p-4">
            {product.images && product.images.length > 0 && (
              <div className="relative h-48 mb-4">
                <img
                  src={`/uploads/${product.images[0]}`}
                  alt={product.name}
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            )}
            <div className="space-y-2">
              <h3 className="text-xl font-bold">{product.name}</h3>
              <p className="text-gray-600">{product.description}</p>
              <p className="text-lg font-bold">{product.price} €</p>
            </div>
          </div>
        ))}
        {products.length === 0 && (
          <div className="col-span-full text-center text-gray-500">
            Нет зарезервированных товаров
          </div>
        )}
      </div>
    </div>
  );
}

export default ReservedProducts;
