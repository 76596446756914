import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ChristmasEffects.css';

function Navbar() {
  return (
    <nav className="bg-gradient-to-r from-[#c41e3a] to-[#8b0000] text-white p-4 shadow-lg relative overflow-hidden">
      <div className="container mx-auto flex items-center justify-center relative z-10">
        <Link to="/" className="text-2xl font-bold text-white flex items-center gap-3 hover:scale-105 transition-transform">
          <span className="text-3xl">🎄</span>
          <span className="text-shadow-lg">Рождественская ярмарка 2024</span>
          <span className="text-3xl">🎅</span>
        </Link>
      </div>
      <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
        <div className="absolute top-0 left-[10%] text-yellow-200 opacity-20 text-4xl">❄</div>
        <div className="absolute top-1 right-[20%] text-yellow-200 opacity-20 text-3xl">❄</div>
        <div className="absolute top-2 left-[30%] text-yellow-200 opacity-20 text-2xl">❄</div>
        <div className="absolute top-0 right-[40%] text-yellow-200 opacity-20 text-3xl">❄</div>
      </div>
    </nav>
  );
}

export default Navbar;
