import React, { useState, useEffect } from 'react';
import { useParams, Navigate, Link } from 'react-router-dom';
import axios from 'axios';

// Use window.location.origin to get the current domain
const BACKEND_URL = window.location.origin;

function ManagementPage() {
  const { token } = useParams();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAvailableFilter, setIsAvailableFilter] = useState(null);
  const [newProduct, setNewProduct] = useState({
    name: '',
    description: '',
    price: '',
    images: []
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [error, setError] = useState('');
  const [isValidToken, setIsValidToken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editingProduct, setEditingProduct] = useState(null);
  const [removedImages, setRemovedImages] = useState([]);

  const MAX_IMAGE_SIZE = 800; // Maximum width/height in pixels
  const QUALITY = 0.8; // Image quality (0.0 to 1.0)
  const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB in bytes

  const resizeImage = (file) => {
    return new Promise((resolve, reject) => {
      // If file is smaller than MAX_FILE_SIZE, don't resize
      if (file.size <= MAX_FILE_SIZE) {
        resolve(file);
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        URL.revokeObjectURL(img.src);

        let width = img.width;
        let height = img.height;

        // Calculate new dimensions while maintaining aspect ratio
        if (width > height) {
          if (width > MAX_IMAGE_SIZE) {
            height = Math.round((height * MAX_IMAGE_SIZE) / width);
            width = MAX_IMAGE_SIZE;
          }
        } else {
          if (height > MAX_IMAGE_SIZE) {
            width = Math.round((width * MAX_IMAGE_SIZE) / height);
            height = MAX_IMAGE_SIZE;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const resizedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            });
            resolve(resizedFile);
          },
          'image/jpeg',
          QUALITY
        );
      };

      img.onerror = (error) => {
        URL.revokeObjectURL(img.src);
        reject(error);
      };
    });
  };

  const handleNewImages = async (files) => {
    const totalImages = editingProduct 
      ? (editingProduct.images?.length || 0) - removedImages.length + files.length 
      : files.length;

    if (totalImages > 5) {
      alert('Максимально 5 изображений');
      return;
    }

    try {
      // Resize all images in parallel
      const resizedFiles = await Promise.all(
        Array.from(files).map(async (file) => {
          if (!file.type.startsWith('image/')) {
            throw new Error(`File ${file.name} is not an image`);
          }
          return await resizeImage(file);
        })
      );

      setSelectedFiles(prevFiles => [...prevFiles, ...resizedFiles]);

      // Create preview URLs for the new files
      const newPreviewUrls = resizedFiles.map(file => URL.createObjectURL(file));
      setPreviewUrls(prevUrls => [...prevUrls, ...newPreviewUrls]);
    } catch (error) {
      console.error('Error processing images:', error);
      alert('Ошибка при обработке изображений. Пожалуйста, попробуйте другие файлы.');
    }
  };

  const handlePasteClick = async () => {
    try {
      // Check if clipboard API is available
      if (!navigator.clipboard) {
        console.error('Clipboard API not available');
        return;
      }

      // Request permission if needed (for mobile)
      if (navigator.permissions) {
        const result = await navigator.permissions.query({ name: 'clipboard-read' });
        if (result.state === 'denied') {
          console.error('Permission to access clipboard denied');
          return;
        }
      }

      // Try to read image from clipboard
      const items = await navigator.clipboard.read();
      for (const item of items) {
        if (item.types.includes('image/png') || item.types.includes('image/jpeg')) {
          const imageType = item.types.find(type => type.startsWith('image/'));
          const blob = await item.getType(imageType);
          const file = new File([blob], `pasted-image-${Date.now()}.jpg`, { type: 'image/jpeg' });
          await handleNewImages([file]);
          break;
        }
      }
    } catch (error) {
      console.error('Error accessing clipboard:', error);
      // Fallback to file input
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.multiple = true;
      input.onchange = (e) => {
        if (e.target.files.length > 0) {
          handleNewImages(Array.from(e.target.files));
        }
      };
      input.click();
    }
  };

  const handlePaste = async (e) => {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    let imageFile = null;

    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        imageFile = item.getAsFile();
        break;
      }
    }

    if (imageFile) {
      const totalImages = editingProduct 
        ? (editingProduct.images?.length || 0) - removedImages.length + 1
        : selectedFiles.length + 1;

      if (totalImages > 5) {
        alert('Максимально 5 изображений');
        return;
      }

      try {
        const resizedFile = await resizeImage(imageFile);
        setSelectedFiles(prevFiles => [...prevFiles, resizedFile]);
        const newPreviewUrl = URL.createObjectURL(resizedFile);
        setPreviewUrls(prevUrls => [...prevUrls, newPreviewUrl]);
      } catch (error) {
        console.error('Error processing pasted image:', error);
        alert('Ошибка при обработке изображения. Пожалуйста, попробуйте другой файл.');
      }
    }
  };

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axios.get('/api/manage/products', {
          headers: { Authorization: token }
        });
        setIsValidToken(true);
        setProducts(response.data);
      } catch (error) {
        setIsValidToken(false);
        setError('Недействительный токен управления');
      } finally {
        setIsLoading(false);
      }
    };

    verifyToken();
  }, [token]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`/api/manage/products${searchTerm ? `?search=${encodeURIComponent(searchTerm)}` : ''}`, {
          headers: { Authorization: token }
        });
        setProducts(response.data);
      } catch (error) {
        setError('Ошибка при загрузке товаров');
        console.error('Error fetching products:', error);
      }
    };

    if (isValidToken) {
      fetchProducts();
    }
  }, [token, isValidToken, searchTerm]);

  useEffect(() => {
    let result = products;

    // Apply search filter
    if (searchTerm) {
      result = result.filter(product => 
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply availability filter
    if (isAvailableFilter !== null) {
      result = result.filter(product => 
        product.is_available === (isAvailableFilter ? 1 : 0)
      );
    }

    setFilteredProducts(result);
  }, [products, searchTerm, isAvailableFilter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', editingProduct ? editingProduct.name : newProduct.name);
    formData.append('description', editingProduct ? editingProduct.description : newProduct.description);
    formData.append('price', editingProduct ? editingProduct.price : newProduct.price);

    if (removedImages.length > 0) {
      formData.append('removedImages', JSON.stringify(removedImages));
    }

    selectedFiles.forEach(file => {
      formData.append('images', file);
    });

    try {
      if (editingProduct) {
        await axios.put(
          `/api/products/${editingProduct.id}`,
          formData,
          {
            headers: {
              'Authorization': token,
              'Content-Type': 'multipart/form-data'
            }
          }
        );
      } else {
        await axios.post(
          '/api/products',
          formData,
          {
            headers: {
              'Authorization': token,
              'Content-Type': 'multipart/form-data'
            }
          }
        );
      }

      setEditingProduct(null);
      setNewProduct({
        name: '',
        description: '',
        price: '',
        images: []
      });
      setSelectedFiles([]);
      setPreviewUrls([]);
      setRemovedImages([]);
      
      const fetchProducts = async () => {
        try {
          const response = await axios.get(`/api/manage/products${searchTerm ? `?search=${encodeURIComponent(searchTerm)}` : ''}`, {
            headers: { Authorization: token }
          });
          setProducts(response.data);
        } catch (error) {
          setError('Ошибка при загрузке товаров');
          console.error('Error fetching products:', error);
        }
      };

      fetchProducts();
    } catch (error) {
      setError(error.response?.data?.error || 'Произошла ошибка');
    }
  };

  const handleDelete = async (product) => {
    if (window.confirm(`Вы уверены, что хотите удалить товар "${product.name}"?`)) {
      try {
        await axios.delete(`/api/products/${product.id}`, {
          headers: { Authorization: token }
        });
        setProducts(products.filter(p => p.id !== product.id));
      } catch (error) {
        setError('Ошибка при удалении товара');
      }
    }
  };

  const handleToggleAvailability = async (product) => {
    try {
      await axios.put(
        `/api/products/${product.id}/availability`,
        { is_available: !product.is_available },
        { headers: { Authorization: token } }
      );
      setProducts(products.map(p =>
        p.id === product.id ? { ...p, is_available: !p.is_available } : p
      ));
    } catch (error) {
      setError('Ошибка при изменении доступности товара');
    }
  };

  const handleEdit = (product) => {
    setEditingProduct(product);
    setRemovedImages([]);
    setSelectedFiles([]);
    setPreviewUrls([]);
  };

  const handleCancel = () => {
    setEditingProduct(null);
    setRemovedImages([]);
    setSelectedFiles([]);
    setPreviewUrls([]);
    setNewProduct({
      name: '',
      description: '',
      price: '',
      images: []
    });
  };

  const handleRemoveImage = (imagePath) => {
    if (editingProduct) {
      setRemovedImages([...removedImages, imagePath]);
    }
  };

  const handleRemoveNewImage = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);

    const newUrls = [...previewUrls];
    URL.revokeObjectURL(newUrls[index]);
    newUrls.splice(index, 1);
    setPreviewUrls(newUrls);
  };

  useEffect(() => {
    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, [editingProduct, removedImages, selectedFiles]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingProduct) {
      setEditingProduct(prev => ({
        ...prev,
        [name]: value
      }));
    } else {
      setNewProduct(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  if (isLoading) {
    return (
      <div className="text-center mt-8">
        <p>Загрузка...</p>
      </div>
    );
  }

  if (!isValidToken) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Управление товарами</h2>
        <Link to={`/reserved/${token}`} className="text-blue-500 hover:text-blue-700">
          Зарезервированные товары
        </Link>
      </div>
      <form id="productForm" onSubmit={handleSubmit} className="mb-8 bg-white shadow-md rounded px-8 pt-6 pb-8">
        <h2 className="text-2xl font-bold mb-4">
          {editingProduct ? 'Редактировать товар' : 'Добавить новый товар'}
        </h2>
        
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Название
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            name="name"
            value={editingProduct ? editingProduct.name : newProduct.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
            Описание
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="description"
            name="description"
            value={editingProduct ? editingProduct.description : newProduct.description}
            onChange={handleInputChange}
            rows="4"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="price">
            Цена
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="price"
            type="number"
            name="price"
            min="0"
            step="0.01"
            value={editingProduct ? editingProduct.price : newProduct.price}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Изображения (максимум 5)
          </label>
          <div className="flex gap-2 mb-2">
            <button
              type="button"
              onClick={handlePasteClick}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm"
            >
              Вставить изображение
            </button>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={(e) => handleNewImages(Array.from(e.target.files))}
              className="hidden"
              id="image-upload"
            />
            <label
              htmlFor="image-upload"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline cursor-pointer text-sm"
            >
              Выбрать файлы
            </label>
          </div>
          <p className="text-sm text-gray-600 mb-2">
            Вставьте изображение из буфера обмена или выберите файлы (максимум 5 изображений)
          </p>
          <div className="flex flex-wrap gap-4 mb-4">
            {editingProduct && editingProduct.images && editingProduct.images
              .filter(img => !removedImages.includes(img))
              .map((img, index) => (
                <div key={index} className="relative w-32 h-32">
                  <img
                    src={`${BACKEND_URL}/uploads/${img}`}
                    alt={`Product ${index + 1}`}
                    className="w-full h-full object-cover rounded"
                    onError={(e) => {
                      console.error('Error loading image:', e);
                      e.target.src = 'https://via.placeholder.com/150?text=No+Image';
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveImage(img)}
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                  >
                    ×
                  </button>
                </div>
              ))}
            {previewUrls.map((url, index) => (
              <div key={`preview-${index}`} className="relative w-32 h-32">
                <img
                  src={url}
                  alt={`Preview ${index + 1}`}
                  className="w-full h-full object-cover rounded"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveNewImage(index)}
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="flex gap-4">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {editingProduct ? 'Сохранить изменения' : 'Добавить товар'}
          </button>
          {editingProduct && (
            <button
              type="button"
              onClick={handleCancel}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Отменить
            </button>
          )}
        </div>
      </form>

      {/* Search input */}
      <div className="mb-8">
        <input 
          type="text" 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Поиск товаров..."
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-red-500"
        />
      </div>

      {/* Availability filter */}
      <div className="mb-8">
        <label className="block text-gray-700 text-sm font-bold mb-2">Фильтр по доступности:</label>
        <div>
          <input 
            type="radio" 
            name="availabilityFilter"
            checked={isAvailableFilter === null}
            onChange={() => setIsAvailableFilter(null)}
          />
          <span className="ml-2">Все</span>
          <input 
            type="radio" 
            name="availabilityFilter"
            checked={isAvailableFilter === true}
            onChange={() => setIsAvailableFilter(true)}
            className="ml-4"
          />
          <span className="ml-2">Доступные</span>
          <input 
            type="radio" 
            name="availabilityFilter"
            checked={isAvailableFilter === false}
            onChange={() => setIsAvailableFilter(false)}
            className="ml-4"
          />
          <span className="ml-2">Зарезервированные</span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredProducts.map(product => (
          <div key={product.id} className="bg-white shadow-md rounded-lg p-4 flex flex-col">
            <div className="flex flex-wrap gap-2 mb-4">
              {product.images && product.images.map((img, index) => (
                <div key={index} className="relative w-32 h-32">
                  <img
                    key={index}
                    src={`${BACKEND_URL}/uploads/${img}`}
                    alt={`${product.name} ${index + 1}`}
                    className="w-full h-full object-cover rounded"
                    onError={(e) => {
                      console.error('Error loading image:', e);
                      e.target.src = 'https://via.placeholder.com/150?text=No+Image';
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="flex-grow">
              <h3 className="text-xl font-bold mb-2">{product.name}</h3>
              <p className="text-gray-600 mb-2">{product.description}</p>
              <p className="text-lg font-bold mb-4">{product.price} €</p>
              <p className="text-gray-600 mb-2">
                {product.buyer_phone && product.buyer_phone.trim() !== '' 
                  ? `Номер покупателя: ${product.buyer_phone}` 
                  : 'Нет'}
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <button
                onClick={() => handleEdit(product)}
                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline text-sm"
              >
                Редактировать
              </button>
              <div className="flex gap-2">
                <button
                  onClick={() => handleToggleAvailability(product)}
                  className={`flex-1 font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline text-white text-sm ${
                    product.is_available ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-green-500 hover:bg-green-600'
                  }`}
                >
                  {product.is_available ? 'Скрыть' : 'Показать'}
                </button>
                <button
                  onClick={() => handleDelete(product)}
                  className="flex-1 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline text-sm"
                >
                  Удалить
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ManagementPage;
