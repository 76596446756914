import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/ChristmasEffects.css';

// Use window.location.origin to get the current domain
const BACKEND_URL = window.location.origin;

function Marketplace() {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`/api/products${searchTerm ? `?search=${searchTerm}` : ''}`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [searchTerm]);

  // Add snowflakes effect
  useEffect(() => {
    const createSnowflake = () => {
      const snowflake = document.createElement('div');
      snowflake.className = 'snowflake';
      snowflake.style.left = Math.random() * window.innerWidth + 'px';
      snowflake.style.animationDuration = Math.random() * 3 + 2 + 's';
      snowflake.style.opacity = Math.random();
      snowflake.innerHTML = '❄';
      document.body.appendChild(snowflake);

      setTimeout(() => {
        snowflake.remove();
      }, 5000);
    };

    const snowInterval = setInterval(createSnowflake, 200);
    return () => clearInterval(snowInterval);
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="mb-8">
        <input 
          type="text" 
          placeholder="Поиск подарков..." 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 rounded-lg focus:outline-none christmas-search"
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {products.map((product) => (
          <div
            key={product.id}
            className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 cursor-pointer flex flex-col christmas-card"
            onClick={() => navigate(`/product/${product.id}`)}
            role="button"
            tabIndex={0}
          >
            <div className="relative h-[300px]">
              {product.images && product.images.length > 0 ? (
                <img
                  src={`${BACKEND_URL}/uploads/${product.images[0]}`}
                  alt={product.name}
                  className="w-full h-full object-contain bg-gray-50 rounded-t-lg"
                  onError={(e) => {
                    console.error('Image load error:', e);
                    e.target.src = 'https://via.placeholder.com/400x300?text=No+Image';
                  }}
                />
              ) : (
                <div className="w-full h-full bg-gray-100 flex items-center justify-center rounded-t-lg">
                  <span className="text-gray-400">No image</span>
                </div>
              )}
            </div>
            <div className="p-4 flex-1">
              <h2 className="text-lg font-semibold text-gray-800 mb-2 line-clamp-1">
                {product.name}
              </h2>
              <p className="text-marketplace-primary font-bold">
                €{product.price.toFixed(2)}
              </p>
              <p className="text-gray-600 mt-2 text-sm line-clamp-2">
                {product.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      {products.length === 0 && (
        <div className="empty-state-christmas">
          <p className="text-xl">Подарки не найдены</p>
          <p>Попробуйте изменить параметры поиска</p>
        </div>
      )}
    </div>
  );
}

export default Marketplace;
