import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import confetti from 'canvas-confetti';

// Use window.location.origin to get the current domain
const BACKEND_URL = window.location.origin;

const triggerConfetti = () => {
  const count = 200;
  const defaults = {
    origin: { y: 0.7 },
    zIndex: 1000,
  };

  function fire(particleRatio, opts) {
    confetti({
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio),
      colors: ['#c41e3a', '#8b0000', '#FFD700', '#228B22', '#FFFFFF'],
    });
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });

  fire(0.2, {
    spread: 60,
  });

  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });

  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });

  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
};

function ProductDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`/api/products/${id}`);
        setProduct(response.data);
        setLoading(false);
      } catch (error) {
        if (error.response?.status === 404) {
          setError('Product not found');
        } else {
          setError('Error loading product details');
        }
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-xl text-gray-600">Загрузка...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-xl text-red-600">Ошибка загрузки товара</p>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-xl text-gray-600">Товар не найден</p>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto mt-8 p-4">
      <button
        onClick={() => navigate('/')}
        className="text-marketplace-primary hover:underline mb-4 inline-flex items-center"
      >
        ← Назад на ярмарку
      </button>
      
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-6">
          {/* Image Gallery */}
          <div className="space-y-4">
            {product.images && product.images.length > 0 ? (
              <>
                <div className="relative h-[400px]">
                  <img
                    src={`${BACKEND_URL}/uploads/${product.images[selectedImageIndex]}`}
                    alt={product.name}
                    className="w-full h-full object-contain bg-gray-50 rounded-lg"
                    onError={(e) => {
                      console.error('Image load error:', e);
                      e.target.src = 'https://via.placeholder.com/400x300?text=No+Image';
                    }}
                  />
                </div>
                
                {product.images.length > 1 && (
                  <div className="grid grid-cols-5 gap-2">
                    {product.images.map((image, index) => (
                      <button
                        key={index}
                        onClick={() => setSelectedImageIndex(index)}
                        className={`relative aspect-square ${
                          selectedImageIndex === index
                            ? 'ring-2 ring-marketplace-primary'
                            : ''
                        }`}
                      >
                        <img
                          src={`${BACKEND_URL}/uploads/${image}`}
                          alt={`${product.name} ${index + 1}`}
                          className="absolute inset-0 w-full h-full object-cover rounded-md"
                          onError={(e) => {
                            console.error('Image load error:', e);
                            e.target.src = 'https://via.placeholder.com/400x300?text=No+Image';
                          }}
                        />
                      </button>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <div className="relative h-[400px]">
                <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                  <span className="text-gray-400">Нет изображения</span>
                </div>
              </div>
            )}
          </div>

          {/* Product Details */}
          <div>
            <h1 className="text-3xl font-bold text-gray-900 mb-4">{product.name}</h1>
            <p className="text-2xl text-marketplace-primary font-semibold mb-6">
              €{product.price.toFixed(2)}
            </p>
            <div className="prose max-w-none">
              <p className="text-gray-600">{product.description}</p>
            </div>
            <div className="flex gap-4 mt-8">
              <button
                onClick={() => {
                  const productUrl = `${window.location.origin}/product/${product.id}`;
                  const message = `Здравствуйте! Меня интересует товар "${product.name}" за €${product.price} на Рождественской ярмарке 2024\n\n${productUrl}`;
                  const encodedMessage = encodeURIComponent(message);
                  triggerConfetti();
                  window.open(`https://wa.me/34633493437?text=${encodedMessage}`, '_blank');
                }}
                className="flex-1 bg-[#25D366] text-white px-6 py-3 rounded-lg hover:bg-[#128C7E] transition-colors flex items-center justify-center gap-2"
              >
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347z"/>
                  <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm6.067 16.783c-.269.838-.842 1.534-1.598 1.988-1.098.658-2.558.893-4.18.637-1.228-.194-2.846-.875-4.194-1.783-2.625-1.768-4.308-4.943-4.417-5.167-.11-.224-.902-1.194-.902-2.277s.557-1.622.756-1.846c.198-.224.438-.334.657-.334l.466.006c.15.002.347-.002.536.375.189.379.647 1.313.706 1.408.059.095.099.206.028.328-.071.123-.133.213-.248.351s-.214.245-.313.371c-.099.127-.202.263-.087.513.116.25.513.988 1.102 1.6.757.79 1.396 1.036 1.595 1.151.198.116.314.097.428-.05.116-.146.466-.542.589-.723.122-.181.244-.151.409-.091s1.062.506 1.244.598c.182.091.305.135.352.21.047.076.047.441-.094.869z"/>
                </svg>
                Хочу купить! Написать в WhatsApp
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
